import React from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from 'styled-components'

import ShareBanner from "../components/UI/share-banner"
import NewsLetterForm from '../components/UI/newsletter-form'
import ContactForm from '../components/UI/contact-form'

const Wrapper = styled.div `

`

 const ContactUs = ({ data }) => {
   
   const title = data.allFile.edges[0].node.childMarkdownRemark.frontmatter.title;


    return (
        <Layout>
          <Wrapper>
            <ContactForm title={title} />
            <NewsLetterForm />
            <ShareBanner />
          </Wrapper>
        </Layout>
    )
}

export const query = graphql`
  {
    allFile(filter: {name: {eq: "contact"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
            }
          }
        }
      }
    }
  }  
`

export default ContactUs;